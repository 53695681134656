import React from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout.jsx';
import Section from '../components/section.jsx';

export default function Blog(props) {
	let { data } = props;
	let { hubspotPost: blog, allBlogs } = data;
	let { nodes: blogs } = allBlogs;
	let { id, title, body, summary, author, published, feature_image } = blog;

	let renderBlogsOverview = [];
	for (let blog of blogs) {
		let splits = blog.url.split('/');
		let slug = splits[splits.length - 1];

		let className = classNames({ 'text-primary': blog.id === id }, { 'text-tertiary': blog.id !== id });
		let iconClassName = classNames(
			'h-8 flex-shrink-0',
			{ 'w-8 mr-1': blog.id === id },
			{ 'w-0 transition-all duration-200 transform group-hover:mr-1 group-hover:w-8': blog.id !== id },
		);

		renderBlogsOverview.push(
			<li key={blog.id} className={className}>
				<Link to={`../${slug}`} className="flex items-center truncate group">
					<svg className={iconClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
					</svg>
					<span className="truncate">{blog.title}</span>
				</Link>
			</li>,
		);
	}

	let seoTitle = `Brainjar | ${title}`;
	let date = new Date(published);

	return (
		<Layout seo={{ description: summary, image: feature_image?.url }}>
			<Helmet>
				<title>{seoTitle}</title>
				<meta property="og:title" content={seoTitle} />
				<meta name="twitter:title" content={seoTitle} />
			</Helmet>

			<section className="grid min-h-screen">
				<div className="z-10 flex flex-col col-start-1 row-start-1 -mt-20 self-y-center">
					<h1 className="max-w-xl text-4xl font-semibold xl:max-w-2xl md:text-5xl 2xl:text-6xl text-primary">
						{title}
					</h1>
					<p className="max-w-lg mt-8" dangerouslySetInnerHTML={{ __html: summary }} />
					<p className="mt-2 text-tertiary">
						<span>{author === '' ? 'Brainjar' : blog.author.name}</span>
						<span> - </span>
						<span>{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</span>
					</p>
				</div>
				<div
					className="right-0 self-x-end filter brightness-75 absolute w-full h-full max-w-[80%] min-h-[50rem] col-start-1 row-start-1 bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${feature_image?.url + '?h=800'})`,
						WebkitMaskImage: 'radial-gradient(ellipse at 60% 40%, white 30%, rgba(0, 0, 0, 0.0) 60%)',
					}}
				></div>
			</section>

			<Section>
				<div className="grid gap-20 xl:grid-cols-[minmax(0,1fr),minmax(15rem,20rem)]">
					<article className="prose max-w-none xl:prose-xl" dangerouslySetInnerHTML={{ __html: body }}></article>
					<div className="sticky w-full max-w-sm top-32 self-y-start">
						<h2 className="text-3xl text-tertiary">Overview</h2>
						<ul className="mt-4 space-y-1">{renderBlogsOverview}</ul>
					</div>
				</div>
			</Section>
		</Layout>
	);
}

export const query = graphql`
	query Blog($id: String!) {
		hubspotPost(id: { eq: $id }) {
			id
			url
			title
			author {
				name
			}
			published
			summary
			body
			topics {
				name
			}
			feature_image {
				url
			}
		}

		allBlogs: allHubspotPost(
			filter: { state: { eq: "PUBLISHED" }, topics: { elemMatch: { slug: { eq: "brainjar" } } } }
		) {
			nodes {
				id
				url
				title
			}
		}
	}
`;
